.comment_flot:focus {
  border: 2px solid #9f9f9f !important;
  box-shadow: none !important;
}

.dwn_btn {
  border-color: #817af3 !important;
  color: #817af3;
}

.serch_btn {
  border-radius: 10px;
  border: 0.5px solid #817af3;
  background: #f8f7ff;
  /* border: none !important; */
}
/* 
.google-visualization-tooltip {
  border-radius: px !important;
  width: 180px !important;
  border-color: white !important;
}

.google-visualization-tooltip-item {
  margin: 0px !important;
}
.google-visualization-tooltip-item-list {
  margin-bottom: 0px !important;
} */

.google-visualization-tooltip-square {
  border-radius: 100% !important;
  width: 10px !important;
  height: 10px !important;
  border-bottom: none !important;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2.5px solid #817af3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 6px;
  height: 12px;
  /* font-weight: 500; */
  border: 3px solid #817af3;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.formgroup1 input:checked + label:after {
  top: 6px !important;
  left: 8px;
}

.profileScore #reactgooglegraph-1 text {
  font-weight: 600 !important;
}

.bankerhome h3 {
  color: #19438c;
  text-align: center;
  /* font-family: Lexend; */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bankerhome p {
  color: #494949;
  text-align: center;
  /* font-family: Lexend; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 28px; 155.556% */
}
