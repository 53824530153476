.fixturesDropdown .css-1jqq78o-placeholder {
  grid-area: 0 !important;
  color: #19477f !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  /* font-weight: 700 !important; */
  padding: 0px !important;
}

.fixturesDropdown .css-13cymwt-control {
  height: 15px;
}

.fixturesDropdown .css-qbdosj-Input input {
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: unset !important;
  font: inherit;
  min-width: 0px !important;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
}
