@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,900;1,400;1,500;1,600&display=swap');

body {
  font-family: 'Work Sans', sans-serif;
  scroll-behavior: smooth;
  background-color: #f7f8f9 !important;
  overflow-x: hidden;
}

/* html {
  --scrollbarBG: white !important;
  --thumbBG: '#817AF3' !important;
} */
body::-webkit-scrollbar {
  width: 11px !important;
  /* background-color: #f7f8f9 !important; */
}

.range {
  border-radius: 50%;
  display: table;
  height: 110px;
  position: relative;
  width: 110px;
}
.range input {
  opacity: 0;
  position: absolute;
}
.range .dial {
  background-color: #fdca40;
  border-radius: 50%;

  height: 20px;
  margin-left: -11px;
  position: absolute;
  top: -11px;
  transform: translateX(93px) translateY(5px);
  width: 20px;
  z-index: 40;
}
.range .dial:focus {
  outline: 0;
}

.range .info {
  background-color: #fff;
  border-radius: 50%;
  bottom: 11px;
  left: 11px;
  position: absolute;
  right: 11px;
  top: 11px;
  z-index: 30;
}

.slice {
  border-radius: 186px 0 0 186px;
  /* background-color: #fdca40; */
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 50%;
  z-index: 10;
}
.slice.right {
  border-radius: 0 186px 186px 0;
  right: 0;
}
.slice.right .blocker {
  left: 0;
  right: auto;
  transform-origin: 0 25%;
}
.slice .blocker {
  background-color: #eee;
  height: 200%;
  position: absolute;
  right: 0;
  transform-origin: 100% 25%;
  width: 200%;
}

.slice1 {
  background-color: #817af3 !important;
}
.slice2 {
  background-color: #21cbe2 !important;
}
.range .dial1 {
  background-color: #817af3 !important;
}
.range .dial2 {
  background-color: #21cbe2 !important;
}

.shadow {
  border-radius: 14px !important;
}

.tour-overlay {
  background: #666;
  opacity: 0.5;
  z-index: 9996;
  min-height: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.tour-stop {
  position: relative;
  z-index: 9998;
  outline: 10px solid white;
}

.tour-stop-bg {
  z-index: 9997;
}

.tour-message {
  z-index: 9997;
}

#reactgooglegraph-1 text {
  font-weight: 800 !important;
}
.react-multi-select-container input[type='text']:focus {
  outline: none;
  border: none;
}

.borderForBulk {
  border-radius: 7.064px;
  border: 0.816px dashed #9e9e9e;
  background: rgba(250, 250, 250, 0.7);
  /* border-spacing: 0.5rem; */
}

.apexcharts-area-series {
  fill-opacity: 0.2; /* Adjust the opacity value as needed */
}

.profilemenu:after {
  content: '';
  position: absolute;
  right: 30px;
  top: -15px;
  /* z-index: -1; */
  width: 17px;
  height: 17px;
  background-image: url('https://pro-manage.s3.ap-south-1.amazonaws.com/Polygon+1.svg');
}

.react-datepicker__input-container input {
  border-radius: 9px !important;
}

/* Hide the default SVG icon */
.apexcharts-menu-icon svg {
  display: none;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-pan-icon {
  display: none;
}

.apexcharts-toolbar {
  gap: 8px !important;
}

/* Add a background image */
.apexcharts-menu-icon {
  background-image: url('https://pro-manage.s3.ap-south-1.amazonaws.com/DownloadIcons.svg');
  background-size: cover;
  width: 124px !important;
  height: 33.9px !important;
  transform: none !important;
}
.apexcharts-reset-icon svg {
  display: none;
}
.apexcharts-reset-icon {
  background-image: url('https://pro-manage.s3.ap-south-1.amazonaws.com/reset.svg');
  background-size: cover;
  width: 29px !important;
  height: 27px !important;
  margin: 0px !important;
  transform: none !important;
}

.apexcharts-toolbar {
  top: -20% !important;
  max-width: 200px !important;
}

.ant-table-thead th {
  background-color: #ccc9ff !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.apexcharts-toolbar {
  flex-direction: row-reverse !important;
  z-index: 7 !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs .ant-tabs-tab {
  padding: 7px 0px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #323b4b !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs .ant-tabs-tab-btn {
  color: #323b4b !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-ink-bar {
  height: 3.4px !important;
  background: #817af3;
  border-radius: 4.53px 4.53px 0px 0px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-top
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-bottom
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-top
  > div
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-tabs-bottom
  > div
  > .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.ant-popover-title {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-tabs-nav {
  padding: 0px 20px 0px 20px !important;
  margin-bottom: 0px !important;
}
.ant-popover-title {
  padding: 20px 20px 10px 20px !important;
}

.ant-popover-inner {
  padding: 0px !important;
}

.headernotification .ant-popover-inner {
  border: 0.1px solid #c5c5c5;
}

.ant-tabs-content-holder {
  min-width: 414px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-popover .ant-popover-title {
  margin-bottom: 0px !important;
}

.spreadOperator {
  position: absolute;
  top: 0%;
  right: -2%;
  content: '';
  width: 17.6em;
  height: 17.6em;
  border-radius: 50%;
  background: #b41e85;
  opacity: 0.38;
  filter: blur(4.3em);
}
.spreadOperator1 {
  position: absolute;
  bottom: 0%;
  left: -2%;
  content: '';
  width: 16.6em;
  height: 16.6em;
  border-radius: 50%;
  background: #817af3;
  opacity: 0.38;
  filter: blur(4.3em);
}

.slide-down {
  animation-name: slideDown;
  animation-duration: 0.5s;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* 
.profilemenu:after {
  content: '';
  position: absolute;
  right: 30px;
  top: -15px; Adjust the distance of the arrow from the bottom
  left: calc(50% - 10px); Adjust the horizontal position of the arrow
  border-width: 10px; Size of the arrow
  border-style: solid;
  border-color: transparent transparent #fff transparent; Adjust the color of the arrow
} */

/* @keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.animate-zoom-in {
  animation: zoomIn 0.3s ease-out;
} */
